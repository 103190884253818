
import { Component, Prop, Watch } from 'nuxt-property-decorator'
import DocInn from '@pp-frontend/shared-packages/classes/doc-value/DocInn'
import DocUin from '@pp-frontend/shared-packages/classes/doc-value/DocUin'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import { FieldsId } from '@pp-frontend/shared-packages/types/enum/FieldsId'
import vSelect from 'vue-select'
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'
import DocPassport from '@pp-frontend/shared-packages/classes/doc-value/DocPassport'
import BaseInput from '~/components/UI/Input/BaseInput.vue'
import InputDate from '~/components/UI/Input/InputDate.vue'

type SupportedTypes = 'passport' | 'inn' | 'uin'

@Component({
  components: {
    InputDate,
    BaseInput,
    vSelect,
    BeforeLeavePopup: () => import(/* webpackPrefetch: true */ '@pp-frontend/popups/component/BeforeLeavePopup.vue')
  }
})
export default class SearchFormFns extends SearchFormAbstract {
  @Prop() showMeta!: boolean
  @Prop() isIframe!: boolean
  @Prop({
    type: Array,
    default: () => ['passport', 'inn', 'uin']
  }) includes!: SupportedTypes[]

  innNotFound: boolean = false
  isLoad: boolean = false
  isError: string = ''
  errorDocVal: string = ''
  selectedType: any

  popupComponent: any = () => import('~/components/popup/Popup.vue')

  docTypeList: any = [
    {
      id: 1,
      name: 'passport',
      label: 'Российский паспорт',
      value: new DocPassport(),
      fieldId: FieldsId.PASPORT
    },
    {
      id: 2,
      name: 'inn',
      label: 'ИНН',
      value: new DocInn(),
      fieldId: FieldsId.INN
    },
    {
      id: 3,
      name: 'uin',
      label: 'Индекс документа (УИН)',
      value: new DocUin(),
      fieldId: FieldsId.UIN
    }]

  allowServices = this.$allowServices()

  @Watch('allowServices', { deep: true })
  allowServicesHandler () {
    if (!this.allowServices.inn_by_passport) {
      this.selectedType = this.docTypeList[1]
      this.docTypeList.shift()
    }
    return this.allowServices
  }

  constructor () {
    super()
    this.selectedType = this.docTypeList[0]
  }

  keydownHandler (map) {
    return {
      ...map,
      8: (e) => {
        e.preventDefault()
      }
    }
  }

  get formData () {
    return this.selectedType.value
  }

  get placeholderText () {
    switch (this.formData.docType()) {
      case 'uin':
        return 'Введите УИН начисления'
      case 'passport':
        return 'Серия и номер паспорта'
      default:
        return 'Введите ИНН'
    }
  }

  get errorText () {
    switch (this.formData.docType()) {
      case 'inn':
        return 'Формат поля ИНН: 10 или 12 цифр'
      case 'uin':
        return 'Формат поля УИН: 20 или 25 цифр'
      case 'passport':
        return 'Формат паспорта: 0000 000000'
      default:
        return 'Поле обязательно'
    }
  }

  getType (): string {
    return 'fns'
  }

  mounted () {
    // if (this.$allowServices().inn_by_passport && this.includes.includes('passport')) {
    //   this.docTypeList.unshift({
    //     id: 1,
    //     name: 'passport',
    //     label: 'Российский паспорт',
    //     value: new DocPassport(),
    //     fieldId: FieldsId.PASPORT
    //   })
    //
    //   this.selectedType = this.docTypeList[0]
    // }

    this.$lastActivity.setEventListener(Events.SEARCH_FORM_SUBMIT)
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      const getDocType = () => {
        return error.payerData[0].document_type
      }
      this.errorDocVal = error.error.response.data.error.document_value[0]
      this.isError = getDocType()
      this.formData.docType = getDocType
    })
    this.allowServicesHandler()
  }

  async afterSubmitBehavior () {
    this.isLoad = true
    let data = this.formData
    let documentType = this.formData.docType()
    if (this.formData.docType() === 'passport') {
      documentType = 'inn'
      data = await this.$axios.post(process.env.API_ENDPOINT + 'fns/inn-by-passport', {
        document_type: this.formData.docType(),
        document_value: this.formData.document_value.document_value,
        owner_name: this.formData.document_value.fio,
        additional_fields: {
          birth_date: this.formData.document_value.birth_date.split('.').reverse().join('-')
        }
      }).then(response => ({
        document_value: response.data.document_value
      })).catch(() => null)

      if (!data) {
        this.innNotFound = true
        this.isLoad = false
        return
      }
    }

    if (this.isIframe) {
      const qs = require('qs')
      const params: any = Object.assign({
        document_type: documentType
      }, data)
      const queryString = qs.stringify(params)
      const url = `https://gos-oplata.ru/search/${this.getType()}?${queryString}`

      if (this.$route.query.target === 'parent') {
        // @ts-ignore
        window.parent.location = url
      } else {
        const win: any = window.open(url, '_blank')
        win.focus()
      }
    } else {
      this.$router.push({
        path: '/search/' + this.getType(),
        query: (Object.assign({
          document_type: documentType
        }, data) as any)
      })
    }

    this.isLoad = false
    return Promise.resolve()
  }
}
